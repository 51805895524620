import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutGalerieListeTirages from "../../../../composants/layout/en/LayoutGalerieListeTirages"

import { obtenirImages, obtenirPage } from "../../../../js/client-es/utils"
import fragmentsImages from "../../../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageVenteTiragesAuroresBoreales: allPagesCompilationYaml(
      filter: { code: { eq: "venteTiragesAuroresBorealesEN" } }
    ) {
      ...FragmentPageYaml
    }
    imagesPortrait: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "auroreHvitserkur"
            "auroreTaiga"
            "auroreGimsoy"
            "aurorePlageAbandonnee"
          ]
        }
      }
    ) {
      ...FragmentImagePaysage
    }
  }
`

export default function NorthernLightsPrints() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageVenteTiragesAuroresBoreales")
  const images = obtenirImages(resultatsRequete, "imagesPortrait")

  return <LayoutGalerieListeTirages page={page} images={images} />
}
